import { defineStore } from 'pinia'
import { toRefs } from 'vue'
import { Logger } from '../diagnostics/logger'
import { http } from '../http'
import { api, User } from '../http/api'
import { ImageUtil, type PiniaStore } from '../utils'
import { chooseFile } from '../utils/file'
import { zDefaultInstance } from '../utils/zod'
import { UserSettingsSchema } from './models'

export const useSettings = defineStore('user-settings', {
  state: () => zDefaultInstance(UserSettingsSchema),

  getters: {
    useExternalPaywall: () => globalThis?.location?.search.includes('use-external-payment')
  },

  actions: {
    getAppSettings<T extends 'contactForm' | 'listenPage'>(key: T) {
      const { theme: themeIndex, ...data } = Object.assign({}, this[key])

      return {
        ...data,
        theme: this.themes[themeIndex]
      }
    },

    async updateAvatar(file?: File | Blob) {
      file ??= await chooseFile()

      if (!file)
        return Logger.debug('File was not provided. Possibly cancelled by user.')

      // Resize logo
      file = await ImageUtil.resize(file, 1024, { type: 'image/png' })
      this.user.avatarUrl = await api.account.updateAvatar(file)
    }
  },

  sync: {
    beforeGet: () => http.onAuthorized(),
    get: () => http.get<any>('/personalisation'),
    set: data => http.post('/personalisation', data)
  }
})

export const useUserAccount = defineStore('user-account', {
  state() {
    const settings = useSettings()

    return {
      ...User.PlaceHolder(),
      ...toRefs(settings.user)
    }
  },

  sync: {
    beforeGet: () => http.onAuthorized(),

    async get(state) {
      const data = await api.account.getUser().catch((error) => {
        console.error('Failed to get user data', error)
      })

      if (!data)
        return {}

      data.name = state.name
      data.avatarUrl = state.avatarUrl!

      return data
    }
  }
})

/** Represents store type of {@link useSettings} */
export type SettingsStore = PiniaStore<typeof useSettings>

/** Represents store type of {@link useUserAccount} */
export type UserStore = PiniaStore<typeof useUserAccount>
