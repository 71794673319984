import type { PiniaPluginContext } from 'pinia'
import { debounce, type Nullable } from '@antfu/utils'
import { promiseDebounce, sanitizeObject } from '../../utils/object'

namespace SyncPlugin {
  export interface Options<State> {
    get: (state: State) => Promise<Nullable<Partial<State>>>
    set?: (state: State) => Promise<any>

    /** Awaited before `get` is called. Can be use to wait for authorization */
    beforeGet?: () => Promise<void>

    /** Called when error occurres in get/set hook */
    onError?: (error: unknown) => void
  }

  export interface Methods {
    refresh: () => void
    update: () => void
  }
}

declare module 'pinia' {
  // eslint-disable-next-line unused-imports/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    /** Sync plugin options */
    sync?: SyncPlugin.Options<StoreState<Store>>
  }

  export interface PiniaCustomProperties {
    sync: SyncPlugin.Methods
  }
}

export interface SyncPluginOptions<State = any> {
  get: (state: State) => Promise<Nullable<State>>
  set?: (state: State) => Promise<void>

  /** Awaited before `get` is called. Can be use to wait for authorization */
  beforeGet?: () => Promise<void>

  /** Called when error occurres in get/set hook */
  onError?: (error: unknown) => void
}

export interface SyncPluginMethods {
  refresh: () => void
  update: () => void
}

export function PiniaSyncPlugin(context: PiniaPluginContext) {
  const { store, options } = context
  const { sync } = options

  if (!sync)
    return

  function wrapCall<T extends () => any>(fn: T, errorContext: string): Nullable<ReturnType<T>> {
    try {
      return fn()
    }
    catch (error) {
      console.error(errorContext, '\n\n', error)
      sync?.onError?.(error)
    }
  }

  /** Fetch data from server */
  const refresh = promiseDebounce(async () => {
    await sync.beforeGet?.()

    const data = await wrapCall(
      () => sync.get(store.$state),
      `Failed to fetch data for store: ${store.$id}`
    )

    if (data)
      store.$patch(data)
  })

  /** Update data to server */
  const update = debounce(500, () => {
    const state = sanitizeObject(store.$state)

    wrapCall(
      () => sync.set?.(state),
      `Failed to post data for store: ${store.$id}`
    )
  })

  // Assign store prop
  store.sync = { refresh, update }

  // Update on state change
  if (sync.set)
    store.$subscribe(update)

  // Load initial state
  refresh()
}
