export class Plan {
  constructor(
    props: Plan.Props,
    readonly name: string = props.name,
    readonly traits: string[] = props.traits || [],
    readonly shortTraits: string[] = props.shortTraits || [],
    readonly features: Plan.Feature[] = props.features || [],
    readonly packages: Plan.Package[] = props.packages || []
  ) {}

  get isFree() {
    return this.packages.length <= 0
  }

  getPrice(period: Plan.Period) {
    const list = this.packages

    if (list.length <= 0)
      return 0

    switch (period) {
      case Plan.Period.Lifetime: return 0
      case Plan.Period.Monthly: return list[0].price
      case Plan.Period.Yearly: return list[1].price
    }
  }

  copyWith(props: Partial<Plan.Props>): Plan {
    return new Plan({ ...this, ...props })
  }
}

export namespace Plan {
  /** Props for creating `Plan` instance */
  export type Props = Partial<Plan> & Pick<Plan, 'name'>

  export enum Period {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Lifetime = 'Lifetime'
  }

  export interface Package {
    /** Stripe payment key */
    key: string
    price: number
    period: Period

    /** If this has savings compared to the other plan */
    savings?: number
  }

  // Constructor params
  export type Feature = [name: string, description: string]
  export interface Params {
    packages?: Package[]

    /** Traits can be either be limits or features */
    traits: string[]

    /** Shorter traits can be either be limits or features */
    shortTraits?: string[]

    /** Features explained in detail */
    features: Feature[]
  }
}

export const Plans = {
  PRO: {
    name: 'Pro',
    packages: {
      monthly: {
        key: import.meta.env.VITE_APP_STRIPE_PRICE_ID_PRO_MONTHLY,
        period: Plan.Period.Monthly,
        price: 21
      } as Plan.Package,

      yearly: {
        key: import.meta.env.VITE_APP_STRIPE_PRICE_ID_PRO_YEARLY,
        period: Plan.Period.Yearly,
        price: 197,
        savings: 21
      } as Plan.Package
    },

    features: [
      'Unlimited recordings',
      'Unlimited messages',
      'Advance customization',
      'Add a CTA to voice notes',
      'Auto transcription',
      'Listen insights',
      'Contact form',
      'Use your own domain',
      'File management'
    ]
  }
} as const

export const plans: Plan[] = [
  new Plan({
    name: 'Free',
    traits: [
      'Limited to 1 minute',
      '50 messages per month',
      'No customization',
      'No pausing',
      'Basic contact form',
      'Vocal branding'
    ]
  }),

  new Plan({
    name: 'Pro',
    packages: [
      {
        key: import.meta.env.VITE_APP_STRIPE_PRICE_ID_PRO_MONTHLY,
        period: Plan.Period.Monthly,
        price: 21
      },

      {
        key: import.meta.env.VITE_APP_STRIPE_PRICE_ID_PRO_YEARLY,
        period: Plan.Period.Yearly,
        price: 197,
        savings: 21
      }
    ],

    shortTraits: [
      'No recording limit',
      'Unlimited messages',
      'Advanced customizations',
      'Team members',
      '+ Many more'
    ],

    traits: [
      'Unlimited recording time',
      'Unlimited messages per month',
      'Use your own domain to host voice notes',
      'Recordings management using folders',
      'Advanced theme customizations',
      'Analytics for listen page'
    ],

    features: [
      ['Unlimited recording time', 'You can record voice messages up to many hours long'],
      ['Message customization', 'Customise your picture, signature, CTA, ...'],
      ['Transcription', 'Automatically generated transcription available in the web player'],
      ['Invite your team', 'You can invite others to be a part of your team'],
      ['Custom domain', 'Using CNAME, host voice notes on your domain'],
      ['File management', 'Easily organize and group your recordings using folders'],
      ['Advance Customization', 'You can customize the, font, colors and theme on all Vocal features'],
      ['Listen page analytics', 'Know when someone listened to your voice message']
    ]
  })
]
