import type { API } from '../types'
import { http } from '../../index'
import { PaymentHistory, User } from './models'

export const account = {
  getUser: () => http.get<User>('/account').then(User.parse),

  /** Get public user data using their invite code */
  getPublicUser: (inviteCode: string) => http.static.get(http.getURL(`/share/${inviteCode}`)).json(User.PublicSchema.parse),

  delete: () => http.post<{ success: boolean }>('/delete', {}),

  update: (data: Partial<User>) => http.post<User>('/account', data).then(User.parse),

  updateAvatar: (file: Blob) => http.upload('image', { img: file }).then(e => String(e.url)),

  redeem: (code: string) => http.post<API.Subscription.RedeemResult>('/coupon', { coupon: code }),

  async subscribe(data: API.Subscription.Params): Promise<API.SuccessResponse<{ checkout_url?: string }>> {
    const result: API.Subscription.CheckoutResult = await http.post<any>('/subscription', data)
      .catch(res => res?.json || res)

    if ('checkout_url' in result)
      return result

    return {
      success: ['active', 'trialing'].includes(result.status),
      message: ''
    }
  },

  unsubscribe: () => http.delete('/subscription').then(() => true, () => false),

  async getPayments() {
    const { payments = [] } = await http.get<{ payments: PaymentHistory[] }>('/payments')

    return payments.map(item => new PaymentHistory(item))
  }
}

export const auth = {
  async login<T extends API.Auth.Provider>(provider: T, params: API.Auth.LoginParams<T>) {
    const endpoint = provider === 'microsoft' ? 'microsoft-auth' : 'auth'
    const result = await http.static.post(params, http.getURL(endpoint)).json<API.Auth.LoginResult>()

    return result
  }
}
