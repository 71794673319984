import wretch from 'wretch'
import { dedupe } from 'wretch/middlewares'
import { HTTPController } from './middleware'

export * from './reactive'
export * from './storage'

export class HTTP extends HTTPController {
  static = wretch()

  baseURL = `${import.meta.env.VITE_APP_API_URI}/`
  request = wretch(this.baseURL)
    .headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
    .middlewares([
      this.middleware(),
      dedupe()
    ])
    .catcherFallback(this.onError.bind(this))

  getURL = (path: string) => new URL(path, this.baseURL).href

  // Methods
  get = <T>(url: string) => this.request.get(url).json<T>()
  post = <T>(url: string, data: any) => this.request.post(data, url).json<T>()
  patch = <T>(url: string, data: any) => this.request.patch(data, url).json<T>()
  put = <T>(url: string, data: any) => this.request.put(data, url).json<T>()
  delete = <T>(url: string, data: any = {}) => this.request.body(JSON.stringify(data)).delete(url).json<T>()

  upload(endpoint: string, files: Record<string, Blob>) {
    const body = new FormData()

    Object.entries(files)
      .forEach(([name, file]) => body.append(name, file))

    return fetch(this.getURL(endpoint), {
      method: 'POST',
      body,
      headers: {
        Authorization: `Bearer ${this.tokens.accessToken}`
      }
    })
      .then(e => e.json())
  }
}

export const http = new HTTP()
