<script lang="ts" setup>
useSeoMeta({
  ogImage: '/favicon-thumbnail.png',
  twitterTitle: 'Vocal',
  twitterDescription: 'Easily share voice messages',
  twitterImage: '/favicon-thumbnail.png',
  twitterCard: 'summary_large_image'
})

useHead({
  link: [
    { rel: 'icon', type: 'image/png', href: '/icons/logo-128.png' }
  ]
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right, #C9E4FE 0%, #A3CEFD 50%, #267EFA 100%)" />
  </NuxtLayout>
</template>

<style lang="sass">
.page-transition-fade
  &-enter-active,
  &-leave-active
    transition: opacity 250ms

  &-enter-from,
  &-leave-to
    opacity: 0
</style>
