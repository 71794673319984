import { getLuminance, lighten, mix, transparentize } from 'color2k'

export interface Theme extends Theme.Metadata, Theme.Colors {}

export namespace Theme {
  export type HexFormat = `#${string}`

  export interface Colors {
    primary: HexFormat
    neutral: HexFormat
    background: HexFormat
  }

  export interface Metadata {
    name: string
    preset: boolean
    font: string
  }

  export type Props = Metadata & Colors

  export type ColorKey = keyof Colors
  export type CSSColorRecord = Record<string, string | Parameters<CSSTheme['getColor']> | ((theme: Theme) => string)>
}

export class CSSTheme {
  constructor(public props: Theme) {}

  static of(props: Theme) {
    return new CSSTheme(props)
  }

  /** Updates props, which changes the theme. Providing a preset will use data from system presets */
  update(props: Theme) {
    this.props = props
  }

  getColor(key: keyof Theme.Colors, opacity = 1, shade?: number) {
    let color = String(this.props[key])

    if (opacity !== 1)
      color = transparentize(color, 1 - opacity)
    if (shade)
      color = lighten(color, shade)

    return color
  }

  getCSSVars(record: Theme.CSSColorRecord) {
    return Object.entries(record).reduce((map, [key, args]) => {
      let output: string

      if (typeof args === 'string')
        output = args

      else if (typeof args === 'function')
        output = args(this.props)

      else
        output = this.getColor(...args)

      map[`--${key}`] = output
      return map
    }, {} as Record<string, string>)
  }

  toMap(): Theme {
    return JSON.parse(JSON.stringify(this.props))
  }

  getTextColor(bgColor: Theme.ColorKey, {
    mixWeight = 0.14,
    luminanceDiff = 0.3
  } = {}) {
    const theme = this.props
    const luminance = getLuminance(theme[bgColor])
    const base = luminance > luminanceDiff ? '#000' : '#fff'

    return mix(base, theme.neutral, mixWeight)
  }

  /** Returns a map of css color variables */
  getVariables(colors?: Theme.CSSColorRecord) {
    return this.getCSSVars({
      // Base colors
      'color-primary': ['primary'],

      'color-neutral': ['neutral'],
      'color-neutral-border': ['neutral', 0.2],

      'color-background': ['background'],
      'color-background-elevated': ['background', 1, 0.1],
      'color-background-elevated-2': ['background', 1, 0.2],

      // Text
      'color-text-on-primary': () => this.getTextColor('primary'),
      'color-text-on-neutral': () => this.getTextColor('neutral'),
      'color-text-on-background': () => this.getTextColor('background'),

      ...colors
    })
  }

  /** Returns css stylesheet of color variables */
  getVariablesCSS(colors?: Theme.CSSColorRecord) {
    const data = this.getVariables(colors)
    const rules: string[] = []

    for (const key in data) {
      const value = data[key]
      rules.push(`${key}: ${value} !important`)
    }

    return rules.join(';')
  }
}

/** Presets of themes */
export const Themes: Theme[] = Object.seal([
  { name: 'Light', preset: true, primary: '#267EFA', neutral: '#364F79', background: '#F3F9FF', font: 'Inter' },
  { name: 'Slate', preset: true, primary: '#ffffff', neutral: '#a6a6a6', background: '#3d3d3d', font: 'Inter' },
  { name: 'Black', preset: true, primary: '#ffffff', neutral: '#c4c4c4', background: '#000000', font: 'Inter' },
  { name: 'Exotic Black', preset: true, primary: '#ff7a7a', neutral: '#ffb8b8', background: '#130707', font: 'Inter' }
])

/**
 * Safely returns theme from props.
 * - Returns latest preset if supplied props matches with system presets
 */
export function getTheme(props?: Theme) {
  // Return default theme
  if (!props)
    return Themes[0]

  // Returns from preset list if given props is from preset
  // Otherwise returns a new Theme instance
  return Themes.find(item => props.preset && item.name === props.name) || props
}
