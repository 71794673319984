import { ErrorService } from '../diagnostics'

export async function fetchBase64(src: string | Blob | File, mode?: RequestMode, stripHeader = false) {
  const reader = new FileReader()
  const blob = typeof src === 'string'
    ? await fetch(src, { mode }).then(i => i.blob())
    : src

  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      let data = reader.result

      if (typeof data !== 'string')
        return reject(new Error('Failed to convert blob to base64'))

      if (stripHeader)
        data = data.split(',')[1]

      resolve(data)
    }

    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export function downloadFile(input: string | Blob, filename: string) {
  const isBlob = input instanceof Blob
  const url = isBlob ? URL.createObjectURL(input) : input

  const a = document.body.appendChild(
    document.createElement('a')
  )

  a.href = url
  a.download = filename
  a.click()
  a.remove()

  if (isBlob)
    URL.revokeObjectURL(url)
}

export async function uploadFiles(url: string, ...files: [name: string, filename: string, blob: Blob][]) {
  const form = new FormData()

  files.forEach(([name, filename, blob]) => {
    const file = new File([blob], filename)
    form.append(name, file, filename)
  })

  return fetch(url, { body: form, method: 'POST' })
    .then((res) => {
      if (res.status === 200)
        return true
      throw new Error(`(${res.status}) ${res.statusText} -> ${res.url}`)
    })
    .catch((error) => {
      ErrorService.log(error, {
        title: 'Failed to upload files',
        level: 'fatal',
        contexts: {
          'Upload Data': files.reduce((record, [key, filename, blob]) => {
            record[key] = { filename, size: blob.size }
            return record
          }, { url } as Record<string, any>)
        }
      })

      return false
    })
}

export function chooseFile({
  /** Sets or retrieves a comma-separated list of content types. */
  accept = '*',

  /** Sets or retrieves the Boolean value indicating whether multiple items can be selected from a list. */
  multiple = false
} = {}) {
  return new Promise<File | undefined>((resolve) => {
    const input = document.createElement('input')
    input.accept = accept
    input.multiple = multiple

    const getFile = () => input.files?.[0]

    window.addEventListener('focus', () => setTimeout(() => !getFile() && resolve(undefined), 300), { once: true })
    input.addEventListener('change', () => resolve(getFile()), { once: true })

    input.type = 'file'
    input.click()
  })
}

export async function cacheFetch(url: string, type: 'script' | 'wasm') {
  try {
    if (!window.caches)
      return fetch(url)

    const cache = await caches.open(type)
    const cachedResponse = await cache.match(url)

    return cachedResponse || fetch(url).then((res) => {
      cache.put(url, res.clone())
      return res
    })
  }

  catch (error) {
    console.warn('Failed to cache file')
  }
}
