import type { App } from 'vue'
import { httpClientIntegration } from '@sentry/integrations'
import { captureException, captureUserFeedback, init, type Scope, setContext, setUser, type SeverityLevel, type User } from '@sentry/vue'

import { DeferredPromise } from '../utils/object'
import { mount } from '../utils/vue/mount'
import { type ReportContext, ReporterUI } from './error-service-ui'
import { probeError } from './error-service-utils'

export * from './error-service-utils'

const log = console.debug.bind(null, '%c🤖 ErrorService:', 'color: #ff4944')

export const Sentry = { captureException, captureUserFeedback, init, setContext, setUser }
export type SentrySDK = typeof Sentry

export namespace ErrorService {
  export interface Config {
    user?: User
    tags?: Record<string, string>
    platform: string
  }

  export interface LoggerConfig {
    /** Sets the provided error as cause to a new error with given message title */
    title?: string

    /** Sets the level on the scope for future events. */
    level?: SeverityLevel

    /** Set an object that will be merged sent as extra data with the event. */
    extras?: Record<string, any>

    contexts?: Record<string, Record<string, any>>

    withScope?: (scope: Scope) => void
  }

  export type ErrorLog = Partial<{
    id: string
    error: any
    timestamp: number
  }>
}

export class ErrorService {
  static ready = new DeferredPromise<void>()
  static errorLogs: ErrorService.ErrorLog[] = []

  /** Installer for vue app */
  static async install(app: App, config: ErrorService.Config) {
    if (import.meta.env.VITEST) {
      console.warn('ErrorService loading skipped in Vitest')
      return
    }

    const { user, tags, ...appContext } = config
    const isDev = import.meta.env.DEV
    const release = import.meta.env.SENTRY_RELEASE || '0.0.0'

    init({
      app,
      release,

      debug: isDev,
      logErrors: isDev,
      environment: import.meta.env.MODE,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,

      sendDefaultPii: true,
      maxValueLength: 500,

      tracesSampleRate: 1,
      tracePropagationTargets: ['localhost', 'vocal-dev.herokuapp.com', 'api.vocal.email'],

      ignoreErrors: [
        'ResizeObserver loop completed with undelivered notifications',
        'Unable to preload CSS'
      ],

      beforeSend: (event, hint) => {
        this.errorLogs.push({
          id: hint.event_id,
          error: hint.originalException || hint.syntheticException,
          timestamp: event.timestamp
        })

        return event
      },

      integrations: [
        httpClientIntegration({
          failedRequestStatusCodes: [[400, 599]]
        }) as never
      ],

      initialScope: { user, tags }
    })

    setContext('Application', appContext)
    log(`SDK has been initialized - ${release}`)

    this.ready.resolve()
  }

  static async identify(user: User) {
    await this.ready
    setUser(user)
  }

  static async log(error: any, config: ErrorService.LoggerConfig = {}) {
    await this.ready

    const event = captureException(error, (scope) => {
      if (config.title)
        scope.setTransactionName(config.title)

      scope.setLevel(config.level ?? 'error')
      scope.setExtras(config.extras ?? {})

      if (config.contexts) {
        Object.entries(config.contexts)
          .forEach(args => scope.setContext(...args))
      }

      config.withScope?.(scope as any)

      return scope
    })

    return event
  }

  /** Reporter for user feedback */
  static async reportFeedback(props: ReportContext = {}) {
    await this.ready

    // Attach past errors
    props.errorLogs = this.errorLogs

    const node = mount(ReporterUI, {
      element: document.body,
      props: {
        ...props,

        // Component props
        sdk: Sentry,
        onClose() {
          node.destroy()
        }
      } as any
    })
  }

  /** Runs function and logs predetermined error context */
  static async try<T>(fn: () => T, config: ErrorService.LoggerConfig = {}) {
    return probeError(fn, (error) => {
      ErrorService.log(error, config)
      return undefined
    })
  }
}
