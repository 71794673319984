export function isCustomDomain(
  /** Check if passed domain is custom */
  domain?: string
) {
  const baseOrigin = import.meta.env.VITE_APP_WEBAPP_ORIGIN

  if (domain)
    return !baseOrigin.includes(domain)

  const { value } = useState('is-custom-domain', () => {
    const url = useRequestURL()
    return url.origin !== baseOrigin
  })

  return value
}
