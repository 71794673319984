import { getRootDomain } from 'core'

export default defineNuxtRouteMiddleware((to) => {
  if (!import.meta.client)
    return

  const { origin } = location
  const rootDomain = getRootDomain(origin)

  // Ignore on vocal domain
  if (!isCustomDomain(origin) || origin === rootDomain)
    return

  // Ignore /listen - /join pages
  if (to.path.match(/\/listen|\/join/i))
    return

  return navigateTo(rootDomain, {
    external: true
  })
})
