/// <reference types="chrome" />

import { EventManager } from './events'
import { Obfuscator } from './utils'

interface BaseStorageEvents {
  init: void
  update: string[]
}

export abstract class BaseStorage<T = object> extends EventManager<BaseStorageEvents & T> {
  isReady = true

  constructor() {
    super()

    // Trigger init event if storage is ready
    if (this.isReady)
      this.emit('init')

    // Update isReady when init is triggered
    this.once('init', () => this.isReady = true)
  }

  abstract get(key: string): any
  abstract set(key: string, value: any): void
  abstract remove(key: string): void
  abstract clear?(): void

  protected encrypt(value: any) {
    return Obfuscator.encrypt(value)
  }

  protected decrypt(value: any) {
    return Obfuscator.decrypt(value)
  }
}

export class MemoryStorage<T> extends BaseStorage<T> {
  #source = {}! as Record<string, any>

  get(key: string) {
    return this.#source[key]
  }

  set(key: string, value: any) {
    this.#source[key] = value
  }

  remove(key: string) {
    delete this.#source[key]
  }

  clear() {
    this.#source = {}
  }
}

export class LocalStorage extends BaseStorage {
  constructor() {
    super()

    window.addEventListener('storage', ({ key }) => key && this.emit('update', [key]))
  }

  get(key: string) {
    return this.decrypt(localStorage.getItem(key))
  }

  set(key: string, value: any) {
    localStorage.setItem(key, this.encrypt(value))
  }

  remove(key: string) {
    localStorage.removeItem(key)
  }

  clear() {
    localStorage.clear()
  }
}

export enum StorageType {
  Memory = 'memory',
  Local = 'local'
}

// TODO: refactor storage usage with http
export function getStorage(type?: StorageType) {
  return type === StorageType.Memory ? new MemoryStorage() : new LocalStorage()
}
