import type { defineStore } from 'pinia'

/** Parses generic of `useXXX` store */
export type PiniaStore<T extends (...args: any) => any> = Omit<
  ReturnType<T>,
  keyof ReturnType<typeof defineStore>
>

export type Undef<T> = T | undefined
export type Mutable<T> = { -readonly [P in keyof T]: T[P] }
export type HintedString<T extends string> = T | (string & NonNullable<unknown>)
export type PartialExclude<T, Keys extends keyof T> = Partial<T> & Pick<T, Keys>
export type Prettify<T> = { [K in keyof T]: T[K] } & object

/** Current platform environment */
export enum Platform {
  UNDEFINED = 'undefined',

  WEBAPP = 'web',
  CONTACT_FORM = 'contact-form',

  // 3rd party integrations
  OUTLOOK = 'outlook',
  MSTeams = 'ms-teams',
  ZENDESK = 'zendesk',
  ZOHO_MAIL = 'zoho-mail',

  GMAIL_ADDON = 'mobile',
  FRONT_APP = 'front-app',

  WEB_EXTENSION = 'chrome-voice',
  WEB_EXTENSION_POPUP = 'chrome-voice-popup'
}
