import { FileDate } from '../../audio/utils'

export class PaymentHistory {
  paid = false
  date = new FileDate()
  receipt = ''
  currency = 'USD'
  amount = 0

  get status() {
    return this.paid ? 'Paid' : 'Unpaid'
  }

  constructor(props: Partial<PaymentHistory> = {}) {
    Object.assign(this, {
      ...props,

      // Stripe multiplies the value, so normalize it
      amount: (props.amount || 0) / 100,

      date: FileDate.safeParse(props.date)
    })
  }
}
