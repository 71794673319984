import type { AudioBlob } from 'vocal-recorder'
import type { Platform } from '../../../utils/types'
import type { SuccessResponse } from '../../types'
import type { API } from '../types'
import { http } from '../..'
import { Logger } from '../../../diagnostics'
import { uploadFiles } from '../../../utils/file'
import { zParsePartial } from '../../../utils/zod'
import { ListenViewSchema, Recording } from './models'

export const audio = {
  /** Returns data for uploading audio files */
  requestUpload: (platform?: Platform, isFree = false) => {
    type T = API.Audio.RequestData
    const url = (base: string) => `${base}?platform=${encodeURIComponent(platform ?? location.origin)}`

    return isFree
      ? http.static.get(url(`${http.baseURL}/get-vocal-free`)).json<T>()
      : http.get<T>(url('get-vocal'))
  },

  async upload(id: string, recording: AudioBlob, signature: Blob, context?: Record<string, any>) {
    const endpoint = http.getURL('upload-vocal')
    Logger.tableCollapsed('🎯 Uploading files to server', { endpoint, recording, signature, context })

    return uploadFiles(
      endpoint,
      ['recording', recording.getFilename(id), recording],
      ['image', `${id}.png`, signature],
      ['context', `${id}.json`, new Blob([JSON.stringify(context || '{}')], { type: 'application/json' })]
    )
  },

  remove: (id: string) => http.delete<SuccessResponse>(`/delete/${id}`),

  async getList() {
    const data = await http
      .get<{ audios?: Recording[] }>('audio')
      .then(e => e.audios)

    return (data || [])
      .map(data => Recording.Schema.parse(data))
      .sort((a, b) => b.uploadDate.getTime() - a.uploadDate.getTime())
  },

  getPublic(id: string, {
    /** Custom headers to append */
    headers = {} as Record<string, string>,

    /** If this fetch should be tracked as a listen event */
    trackEvent = false
  } = {}) {
    // Append access token if authorized
    const fetch = http.isAuthorized
      ? http.static.auth(`Bearer ${http.tokens.accessToken}`)
      : http.static

    return fetch
      .headers(headers)
      .get(http.getURL(`view-vocal/${id}?track=${trackEvent}`))
      .json(data => zParsePartial(ListenViewSchema, data))
  },

  edit(id: string, props: Partial<Recording.EditableProps>) {
    return http.post(`edit/${id}`, props)
  },

  getAnalytics(id: string) {
    return http
      .get<{ data: API.Audio.Analytics }>(`/views/${id}`)
      .then(e => e.data || [])
  }
}
