<script lang="ts" setup>
import type { HintedString } from 'core'
import Spinner from '../loaders/Spinner.vue'

const {
  size = 'base',
  variant = 'primary',
  ...props
} = defineProps<{
  size?: HintedString<'base' | 'md' | 'sm'>
  variant?: HintedString<'primary' | 'secondary' | 'ghost'>
  title?: string

  label?: string
  loading?: boolean
  loadingLabel?: string
  disabled?: boolean

  /** Resolves a promise callback and shows loader */
  onResolveClick?: () => any | Promise<any>
}>()

const isResolving = ref(false)

async function onClick() {
  const fn = props.onResolveClick
  if (!fn)
    return

  isResolving.value = true
  await fn()
  isResolving.value = false
}

defineExpose({
  click: onClick
})
</script>

<template>
  <button
    type="button" class="cta" :class="[`${variant}-variant`, `${size}-size`]"
    :freeze="disabled || loading || isResolving" :disabled :title @click="onClick"
  >
    <Transition name="fade-up" mode="out-in">
      <div v-if="loading || isResolving">
        <Spinner size="xs" />
        {{ loadingLabel }}
      </div>

      <div v-else class="[&>.svg-icon]:w-4 [&>.svg-icon]:h-4 [&>.svg-icon]:flex-shrink-0">
        <slot>{{ label }}</slot>
      </div>
    </Transition>
  </button>
</template>

<style scoped lang="sass">
.cta
  --fade-up-distance: 5px

  @apply overflow-hidden cursor-pointer select-none
  @apply rounded-5px border-1 border-solid border-transparent outline-2 outline-solid outline-transparent outline-offset-2
  @apply transform-gpu active:scale-97

  // Interaction states
  transition: all 200ms
  transition-property: color, background-color, border-color, outline-color, transform

  &[freeze="true"]
    @apply pointer-events-none

  // Slot content
  & > div
    @apply gap-2 flex-center flex-shrink-0 text-label-3 whitespace-nowrap

// Sizes
.cta
  &.base-size
    @apply px-4 h-12

  &.md-size
    @apply px-3 h-10

  &.sm-size
    @apply px-3 h-8

// Color variants
.cta
  &.primary-variant
    @apply $primary-[$color-primary,@theme-colors-primary-500]
    @apply bg-$primary $text-[$color-text-on-primary,@theme-colors-neutral-200]
    @apply $focus-outline-color-$primary

  &.secondary-variant
    @apply $neutral-[$color-neutral,@theme-colors-neutral-600]
    @apply $bg-[$color-background-elevated,@theme-colors-shade-light]
    @apply $border-color-[$color-neutral-border,@theme-colors-neutral-100]
    @apply $text-$neutral $focus-outline-color-$neutral

  @apply bg-$bg text-$text
  @apply not-focus-border-$border-color focus-within-outline-$focus-outline-color active-outline-$focus-outline-color
  @apply disabled:grayscale-80 disabled:opacity-40

  //;
</style>
