import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { PiniaSyncPlugin } from './plugins/sync'

export * from './exports'
export * from './models'

export { PiniaSyncPlugin } from './plugins/sync'

export function usePinia(pinia = createPinia()) {
  return pinia
    .use(PiniaSyncPlugin)
    .use(createPersistedState())
}
