<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{ error: NuxtError }>()

onBeforeMount(() => {
  console.error('Fatal crash', props.error)

  ErrorService.log(props.error, {
    title: 'Fatal error occurred in client app'
  })
})
</script>

<template>
  <NuxtLayout name="basic" class="flex-center">
    <div class="max-w-450px mx-auto">
      <AlertDialog
        icon="i-fluent-error-circle-settings-20-regular"
        title="Uh oh! An error occurred"
        subtitle="You can still continue. If this issue persists please contact us"
      />

      <CTAButton class="mt-6 w-120px !rounded-full" size="md" @click="clearError()">
        Continue
      </CTAButton>
    </div>
  </NuxtLayout>
</template>
