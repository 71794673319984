import revive_payload_client_ng7uo7WHV0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DJht3ESls9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MdEy5YMtbh from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0hcilYVMGt from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-embe_nuopjbg24ljgo2bts7wqpaysle/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_JNxvCddE6F from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6GZXLZPxac from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mFNXTvs9ku from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_ZA2WPi9UZZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_aP8uIUsEfv from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mAOFsdXegH from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.2_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_LDHSqagTgx from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.5.0_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.13.0_jiti_ckmymv42rulpzm5zjg4spcsgim/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_FroD0HKsL8 from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.2_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.2_typescript@5_4pcv6s7njy66moma2wwctg6eyu/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import titles_ZRujFQq0j3 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_vvsunovhqd357pwcyanywauvqy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_e3JdAYZN1h from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-e_6semotbjydjiyekvlmtc2qih5e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_rw9eAhwlt6 from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-e_6semotbjydjiyekvlmtc2qih5e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
import defaults_JmCwtJcVqM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_vvsunovhqd357pwcyanywauvqy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_ng7uo7WHV0,
  unhead_DJht3ESls9,
  router_MdEy5YMtbh,
  _0_siteConfig_0hcilYVMGt,
  payload_client_JNxvCddE6F,
  navigation_repaint_client_6GZXLZPxac,
  check_outdated_build_client_mFNXTvs9ku,
  view_transitions_client_ZA2WPi9UZZ,
  chunk_reload_client_aP8uIUsEfv,
  plugin_vue3_mAOFsdXegH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LDHSqagTgx,
  unocss_MzCDxu9LMj,
  plugin_FroD0HKsL8,
  titles_ZRujFQq0j3,
  siteConfig_e3JdAYZN1h,
  inferSeoMetaPlugin_rw9eAhwlt6,
  _00_setup_OesUu83x3t,
  defaults_JmCwtJcVqM
]