/** Shallow sanitizes a object using JSON methods */
export function sanitizeObject<T>(record: T): T {
  return JSON.parse(JSON.stringify(record))
}

export function usePromiseRunner<Fn extends (...args: any[]) => Promise<any>>(fn: Fn) {
  let pending: ReturnType<Fn>

  function run(...args: Parameters<Fn>) {
    if (pending)
      return pending

    pending = fn(...args) as any
    pending.finally(() => pending = null as any)

    return pending
  }

  function current(): Promise<void> {
    return pending || Promise.resolve()
  }

  return { run, current }
}

/** Creates singleton promise handler. Runs only one instance at once */
export function promiseDebounce<Fn extends (...args: any) => Promise<any>>(fn: Fn) {
  return usePromiseRunner(fn).run
}

export class DeferredPromise<T> extends Promise<T> {
  resolve: (value: T | PromiseLike<T>) => void
  reject: (reason: any) => void

  initialCallStack: Error['stack']
  resolved = false

  constructor(executor: ConstructorParameters<typeof Promise<T>>[0] = () => { }) {
    let resolver: (value: T | PromiseLike<T>) => void
    let rejector: (reason: T | Error) => void

    super((resolve, reject) => {
      resolver = resolve
      rejector = reject
      return executor(resolve, reject) // Promise magic: this line is unexplicably essential
    })

    this.resolve = (...args) => {
      this.resolved = true
      return resolver(...args)
    }

    this.reject = rejector!

    // store call stack for location where instance is created
    this.initialCallStack = new Error(' ').stack?.split('\n').slice(2).join('\n')
  }

  /** @throws error with amended call stack */
  rejectWithError(error: Error) {
    error.stack = [error.stack?.split('\n')[0], this.initialCallStack].join('\n')
    this.reject(error)
  }
}
