import { DeferredPromise } from './object'

export class ImageUtil {
  static async toBase64(url: string, init?: RequestInit) {
    const response = await fetch(url, init)
    const blob = await response.blob()

    if (!response.ok)
      throw new Error('Failed to load image')

    return new Promise<string>((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result

        if (typeof base64data === 'string')
          resolve(base64data)

        else throw new Error('Null parsing image')
      }
    })
  }

  static async imageFromFile(file: Blob) {
    const task = new DeferredPromise<HTMLImageElement>()

    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => task.resolve(img)
    img.onerror = task.reject

    return task
  }

  static canvasToBlob(canvas: HTMLCanvasElement, type = 'image/webp', quality = 1) {
    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob)
          resolve(blob)
        else reject(new Error('No blob result'))
      }, type, quality)
    })
  }

  static async resize(file: Blob, width = 1024, { step = 0.5, type = 'image/webp', quality = 1 } = {}) {
    const image = await ImageUtil.imageFromFile(file)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!

    canvas.width = width
    canvas.height = canvas.width * image.height / image.width

    if (image.width * step > width) {
      const drawer = document.createElement('canvas')
      const dctx = drawer.getContext('2d')!
      const multiplier = 1 / step

      let size = {
        width: Math.floor(image.width * step),
        height: Math.floor(image.height * step)
      }

      drawer.width = size.width
      drawer.height = size.height

      dctx.drawImage(image, 0, 0, size.width, size.height)

      while (size.width * step > width) {
        size = {
          width: Math.floor(size.width * step),
          height: Math.floor(size.height * step)
        }
        dctx.drawImage(drawer, 0, 0, size.width * multiplier, size.height * multiplier, 0, 0, size.width, size.height)
      }

      ctx.drawImage(drawer, 0, 0, size.width, size.height, 0, 0, canvas.width, canvas.height)
    }
    else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    }

    URL.revokeObjectURL(image.src)
    return this.canvasToBlob(canvas, type, quality)
  }
}

/**
 * Converts image to given format using canvas.
 * @param source can be either url or base64 url
 */
export function convertImage(source: string, { scale = 3, quality = 1, type = 'image/png' } = {}) {
  const result = new DeferredPromise<Blob>()
  const img = new Image()

  img.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!

    // Upscale canvas
    const w = canvas.width = img.width * scale
    const h = canvas.height = img.height * scale

    ctx.drawImage(img, 0, 0, w, h)

    // Render
    canvas.toBlob(
      blob => blob ? result.resolve(blob) : result.reject(null),
      type,
      quality
    )
  }

  img.onerror = (error) => {
    if (error instanceof Event)
      error.preventDefault()

    result.reject(error)
  }

  img.src = source

  return result
}
