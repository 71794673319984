export class EventManager<T> {
  #target = new EventTarget()

  once<K extends keyof T>(key: K, fn: (result: T[K]) => any) {
    return this.on(key, (result, detach) => {
      detach()
      fn(result)
    })
  }

  on<K extends keyof T>(key: K, fn: (result: T[K], detach: VoidFunction) => any) {
    const onTrigger = (event: any) => {
      fn(
        event.detail,
        () => this.#target.removeEventListener(key as string, onTrigger)
      )
    }

    this.#target.addEventListener(key as string, onTrigger)
  }

  emit<K extends keyof T>(key: K, ...args: T[K] extends void ? [] : [payload: T[K]]) {
    if (import.meta.env.SSR)
      return

    this.#target.dispatchEvent(
      new CustomEvent(key as string, { detail: args[0] })
    )
  }
}
