import LOGO_URL from '@assets/images/branding/vocal-identity-logo.svg?url'
import { z } from 'zod'
import { type Theme, Themes } from '../../services/theme'

const zToString = z.string().default

export const ListenPageSettings = z.object({
  theme: z.number().default(0),

  /** Referral CTA button */
  cta: z.object({
    enabled: z.boolean().default(true),
    link: z.string().nullish().default(''),
    message: z.string().default('Send a voice message like this'),
    button: z.string().default('Join for free')
  }),

  reply: z.object({
    enabled: z.boolean().default(true),
    label: z.string().default('Reply')
  }),

  strings: z.object({
    sharedWithYou: zToString('shared this voice message with you')
  })
})

export const ContactFormSettings = z.object({
  theme: z.number().default(0),

  strings: z.object({
    headerTitle: zToString('Send us a message'),
    headerSubtitle: zToString('via voice message'),
    micAccess: zToString('We need access to your mic'),
    grantPermission: zToString('Grant permission'),
    fallbackMessageTitle: zToString('Send a message instead'),
    fallbackInputPlaceholder: zToString('Write your message here'),
    startRecording: zToString('Start recording'),
    stopRecording: zToString('Stop recording'),
    emailInputPlaceholder: zToString('Enter your email address')
  })
})

export const UserSettingsSchema = z.object({
  /** User specific settings */
  user: z.object({
    name: zToString('You'),
    avatarUrl: zToString(LOGO_URL)
  }),

  themes: z.custom<Theme>().array().default([...Themes]),

  /** Base settings */
  defaults: z.object({
    notifications: z.boolean().default(true),
    transcriptions: z.boolean().default(true)
  }),

  attachment: z.object({
    name: zToString('Voice memo'),
    enabled: z.boolean().default(true)
  }),

  recorder: z.object({
    /** ID of the microphone to use */
    deviceId: z.string().optional()
  }),

  signature: z.object({
    enabled: z.boolean().default(true),
    message: zToString('Hey, check out my voice message!'),
    theme: z.number().default(0)
  }),

  contactForm: ContactFormSettings,
  listenPage: ListenPageSettings
})

export type IListenPageSettings = z.infer<typeof ListenPageSettings>
export type IContactFormSettings = z.infer<typeof ContactFormSettings>
export type IUserSettingsSchema = z.infer<typeof UserSettingsSchema>
