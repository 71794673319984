import { ref } from 'vue'

export function toRef(value = false): PropertyDecorator {
  return (target: any, key) => {
    const data = ref(value)

    // Add reactive prop
    Object.defineProperty(target, key, {
      enumerable: true,
      configurable: true,

      get() {
        return data.value
      },

      set(value) {
        data.value = value
      }
    })
  }
}
