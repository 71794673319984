/// <reference types="office-js" />

import type { Undef } from './types'
import { ErrorService } from '../diagnostics'
import { http } from '../http'
import { DeferredPromise } from './object'

export { nanoid as uuid } from 'nanoid'

/** Contains information about the device */
export class device {
  static #eval(fn: (scope: typeof globalThis, ua: string) => boolean) {
    try {
      return fn(
        globalThis || window,
        navigator.userAgent.toLowerCase()
      )
    }
    catch {
      return false
    }
  }

  static get isWebExtension() {
    return this.#eval(scope => !!scope.chrome.runtime.id)
  }

  static get isMobile() {
    return this.#eval((_, ua) => /mobile|android/i.test(ua))
  }

  static get isSafari() {
    return this.#eval(() => 'webkitEnterFullscreen' in HTMLVideoElement.prototype)
  }

  static get isApple() {
    return this.#eval((_, ua) => /Mac|iPhone|iPod|iPad/i.test(ua))
  }

  static get isOutlookWeb() {
    return this.#eval(() => Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline)
  }

  /** Checks if device is using an older browser */
  static get isOld() {
    return this.#eval(scope => scope.CSS.supports('scroll-behavior', 'auto'))
  }

  static get isWindows() {
    return this.#eval((scope, ua) =>
      /Windows/.test(ua) || /Win/.test(scope.navigator.platform)
    )
  }
}

export const display = {
  resize(width: number, height: number) {
    const cx = (screen.availWidth - width) / 2
    const cy = (screen.availHeight - height) / 2

    window.resizeTo(width, height)
    window.moveTo(cx, cy)
  },

  safeResize(width: number, height: number, margin = 100) {
    const size = (value: number, base: number) => Math.min(value, base - margin)
    display.resize(size(width, screen.width), size(height, screen.height))
  }
}

export function isNetworkSlow() {
  const connection = (navigator as any).connection

  // Assume network is slow
  if (!connection)
    return true

  const { effectiveType, downlink } = connection
  return effectiveType !== '4g' || downlink < 10
}

export function sendSupportMail(subject = '', body = '', agent: 'support' | 'bugreport' | 'referrals' = 'support') {
  const email = `mailto:team+${agent}@vocal.email`
  const url = `mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`

  window.open(url)
}

export function openWindow(url: string, {
  promise = new DeferredPromise<void>(),
  target = '',
  features = ''
} = {}) {
  const popup = window.open(url, target, features)

  const timer = setInterval(() => {
    if (popup && !popup.closed)
      return

    clearInterval(timer)
    promise.resolve()
  }, 1500)

  return promise
}

export function openPopup(url: string, width: number, height: number, useOfficeUI = false) {
  const onclose = new DeferredPromise<void>()
  const x = (screen.width - width) / 2
  const y = (screen.height - height) / 2

  // Use microsoft Office Dialog
  if (useOfficeUI && 'Office' in window) {
    Office.context.ui.displayDialogAsync(url, {
      height: 70,
      width: 22,
      promptBeforeOpen: false
    }, (result) => {
      const frame = result.value

      frame.addEventHandler(Office.EventType.DialogMessageReceived, () => frame.close())
      frame.addEventHandler(Office.EventType.DialogEventReceived, () => onclose.resolve())
    })
  }

  // Fallback to window
  else {
    openWindow(url, {
      promise: onclose,
      target: '_blank',
      features: `width=${width},height=${height},left=${x},top=${y}`
    })
  }

  return onclose
}

/** Check if current window is a popup */
export function isPopupWindow() {
  return !!window.opener && window.opener !== window
}

/** Returns all search params from url as json record */
export function getURLSearchParams<T extends Record<string, any>>(url?: string): T {
  const { searchParams } = new URL(url || location.href.split('#').join('?'))
  const params = Array.from(searchParams.entries()).reduce((o, [k, v]) => {
    o[k] = decodeURIComponent(v)
    return o
  }, {} as any)

  return params
}

/** Wrapper for `startViewTransition` */
export function animateDOM(callback = () => { }) {
  console.log('call: animateDOM')

  if ('startViewTransition' in document) {
    const view = document.startViewTransition(callback)
    const hooks = ['ready', 'finished', 'updateCallbackDone'] as const

    // Handle hook errors
    hooks.forEach((name) => {
      view[name].catch(error => console.warn(`animateDOM: error in hook: "${name}"`, error))
    })

    return view
  }
  else {
    callback()
  }

  // Dummy ViewTransition result
  return {
    finished: Promise.resolve(undefined),
    ready: Promise.resolve(undefined),
    updateCallbackDone: Promise.resolve(undefined),
    skipTransition: () => { }
  } satisfies ViewTransition
}

/** Safe alternative for requestIdleCallback */
export function onIdleCallback(callback: VoidFunction) {
  switch (true) {
    case 'requestIdleCallback' in globalThis:
      return requestIdleCallback(callback)

    case 'requestAnimationFrame' in globalThis:
      return requestAnimationFrame(callback)

    default:
      return setTimeout(callback, 0)
  }
}

/** Copies string content to clipboard */
export async function copyText(content: string) {
  try {
    await navigator.clipboard.writeText(content)
    return true
  }

  catch (error) {
    ErrorService.log(error, {
      title: 'Failed to copy content to clipboard',
      extras: { content }
    })

    return false
  }
}

export function copyElementToClipboard(element: HTMLElement) {
  function useFallback() {
    const type = 'text/html'

    return navigator.clipboard.write([
      new ClipboardItem({ 'text/plain': element.textContent ?? '' }),
      new ClipboardItem({
        [type]: new Blob([element.innerHTML], { type })
      })
    ])
  }

  if (!window.getSelection)
    return useFallback()

  const selection = window.getSelection()
  const range = document.createRange()

  range.selectNodeContents(element)

  selection?.removeAllRanges()
  selection?.addRange(range)

  const result = document.execCommand('copy')
  window.getSelection()?.removeAllRanges()

  return result
}

export function copyRichHTML(snippet: string) {
  const node = document.createElement('div')
  node.style.display = 'hidden'
  node.innerHTML = snippet

  document.body.appendChild(node)
  const result = copyElementToClipboard(node)
  document.body.removeChild(node)

  return result
}

/** Checks whether the target is visible inside the viewport */
export function isElementVisible(target: HTMLElement) {
  try {
    const rect = target.getBoundingClientRect()
    return (
      rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  catch (error) {
    console.warn('isElementVisible: failed to check visibility', { target, error })
    return false
  }
}

/** Parses base64 encoded json string */
export function safeParseBase64Json(source: string, fallback: object) {
  try {
    return JSON.parse(decodeURIComponent(source))
  }

  catch (error) {
    console.error('safeParseBase64Json: failed to parse', { source, error })
  }

  return fallback
}

/** Safely parses URL */
export function parseURL(base: string, ...parts: string[]) {
  // Normalize base
  base = base
    .replace(/https?:\/\//gi, '')
    .replace(/\/{2,}/g, '/')

  // Normalize path
  const path = parts
    .join('/')
    .replace(/\/{2,}/g, '/')

  return new URL(path, `https://${base}`)
}

/** Get root origin from given url */
export function getRootDomain(origin: string): string {
  // Remove the protocol (http(s)://) from the origin
  const urlWithoutProtocol = origin.replace(/^(https?:\/\/)/i, '')

  // Split the URL into subdomains and root domain
  const parts = urlWithoutProtocol.split('.')

  // If there are less than 3 parts (e.g., "example.com"), return the original origin
  if (parts.length < 3)
    return origin

  // Combine the last two parts (root domain and top-level domain)
  const rootDomain = parts.slice(-2).join('.')

  return `https://${rootDomain}`
}

/** Whether window opener is self origin */
export function isOpenerSelf() {
  try {
    return window.opener.top.origin === location.origin
  }
  catch { return false }
}

/** Destroys media streams */
export function destroyStreams(...streams: Undef<MediaStream>[]) {
  streams.forEach(stream =>
    stream?.getTracks().forEach((i) => {
      i.stop()
      stream.removeTrack(i)
    })
  )
}

export function openCannyPage(user: any) {
  window.open(
    http.getAuthURL('auth', '/static/canny-redirect', { params: user }),
    '_blank'
  )
}
