import type { PartialDeep } from 'type-fest'
import type { z } from 'zod'
import { deepMerge } from '@antfu/utils'

import { getDefaultsForSchema as zDefaultInstance } from 'zod-defaults'

export { zDefaultInstance }

/** Sets default value for nullable zod schema */
export function zDefault<Schema extends z.ZodSchema>(schema: Schema, value: z.infer<Schema>) {
  return schema.nullish().transform(i => i ?? value) as unknown as Schema
}

/** Parses partial input using default schema values */
export function zParsePartial<T extends z.AnyZodObject>(schema: T, value: PartialDeep<z.infer<T>>): z.infer<T> {
  const defaults = zDefaultInstance(schema) as any
  const merged = deepMerge({}, defaults, value)

  return schema.parse(merged)
}
